import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from '@nextui-org/react';
import { motion } from 'framer-motion';
import axios from 'axios';

const Register = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = async () => {
    try {
      if (!login || !password) {
        alert('Пожалуйста, введите логин и пароль!');
        return;
      }

      await axios.post('http://194.135.36.106:8000/api/register/', {
        username: login,
        password: password,
      });

      alert('Регистрация прошла успешно. Пожалуйста, войдите.');
      navigate('/login');
    } catch (error) {
      console.error('Ошибка при регистрации:', error);
      alert('Ошибка при регистрации.');
    }
  };

  return (
    <div 
      style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', 
        overflow: 'hidden',
        position: 'relative',
        background: 'linear-gradient(135deg, #0f0f0f, #1c1c1c)',
      }}
      onContextMenu={(e) => e.preventDefault()}
      onCopy={(e) => e.preventDefault()}
    >
      <Card css={{ mw: '400px', padding: '40px', backdropFilter: 'blur(10px)', backgroundColor: 'rgba(30, 30, 30, 0.8)', borderRadius: '16px', zIndex: 1, boxShadow: '0 8px 32px rgba(0, 0, 0, 0.37)' }}>
        <motion.div 
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1.2, ease: 'easeOut' }}
          style={{ width: '100%', textAlign: 'center', padding: '10px 0' }}
        >
          <h2 style={{ color: '#ffffff', margin: '0', letterSpacing: '2px', fontWeight: 'bold' }}>Регистрация</h2>
        </motion.div>
        <motion.div 
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1.2, ease: 'easeOut' }}
          style={{ marginBottom: '20px', textAlign: 'center' }}
        >
          <h3 style={{ color: '#ffffff', margin: '0', letterSpacing: '1px' }}>IT_CRM</h3>
        </motion.div>
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1.2, ease: 'easeOut' }}
          style={{ marginBottom: '20px' }}
        >
          <input 
            type="text" 
            placeholder="Логин" 
            value={login} 
            onChange={(e) => setLogin(e.target.value)}
            style={{ 
              width: '100%', 
              padding: '12px', 
              marginBottom: '15px', 
              borderRadius: '8px', 
              border: '1px solid #444', 
              outline: 'none',
              backgroundColor: '#222',
              color: '#e0e0e0',
              fontSize: '1rem'
            }}
          />
          <input 
            type="password" 
            placeholder="Пароль" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)}
            style={{ 
              width: '100%', 
              padding: '12px', 
              marginBottom: '20px', 
              borderRadius: '8px', 
              border: '1px solid #444', 
              outline: 'none',
              backgroundColor: '#222',
              color: '#e0e0e0',
              fontSize: '1rem'
            }}
          />
        </motion.div>
        <motion.div 
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          whileHover={{ scale: 1.1, rotate: 1 }}
          transition={{ duration: 0.4, ease: 'easeInOut' }}
        >
          <Button 
            fullWidth 
            size="lg" 
            style={{
              background: 'linear-gradient(135deg, #ff416c, #ff4b2b)',
              color: 'white',
              borderRadius: '10px',
              padding: '15px',
              fontSize: '1.1rem',
              fontWeight: 'bold',
              cursor: 'pointer',
              border: 'none'
            }}
            onClick={handleRegister} 
            auto 
          >
            Зарегистрироваться
          </Button>
          {/* Блок для входа через Telegram */}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          {/* Замените your_bot_username на имя вашего бота Telegram */}
          <script async src="https://telegram.org/js/telegram-widget.js?7" data-telegram-login="your_bot_username" data-size="large" data-radius="10" data-auth-url="http://194.135.36.106:8000/api/telegram-login/" data-request-access="write"></script>
        </div>
        </motion.div>
        <motion.div 
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1.2, ease: 'easeOut' }}
          style={{ textAlign: 'center', marginTop: '25px' }}
        >
          <span style={{ fontSize: '1rem', color: '#ffffff' }}>Уже есть аккаунт? </span>
          <a href="/login" style={{ color: '#4ade7b', textDecoration: 'none', fontSize: '1rem', fontWeight: 'bold' }}>Войти</a>
        </motion.div>
      </Card>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          pointerEvents: 'none',
          opacity: 0.05,
          overflow: 'hidden'
        }}
      >
        <pre
          style={{
            color: '#ffffff',
            fontSize: '12px',
            lineHeight: '1.5',
            userSelect: 'none',
            whiteSpace: 'pre-wrap',
            padding: '20px'
          }}
        >
          {`import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from '@nextui-org/react';
import { motion } from 'framer-motion';
import axios from 'axios';

const Register = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = async () => {
    try {
      if (!login || !password) {
        alert('Пожалуйста, введите логин и пароль!');
        return;
      }

      await axios.post('http://194.135.36.106:8000/api/register/', {
        username: login,
        password: password,
      });

      alert('Регистрация прошла успешно. Пожалуйста, войдите.');
      navigate('/login');
    } catch (error) {
      console.error('Ошибка при регистрации:', error);
      alert('Ошибка при регистрации.');
    }
  };

  return (
    <div 
      style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', 
        overflow: 'hidden',
        position: 'relative',
        background: 'linear-gradient(135deg, #0f0f0f, #1c1c1c)',
      }}
      onContextMenu={(e) => e.preventDefault()}
      onCopy={(e) => e.preventDefault()}
    >
      ...
    </div>
  );
};

export default Register;`}
        </pre>
      </div>
      <div className="umbreon-marquee" style={{ zIndex: '3' }}>
        <img src="https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/197.png" alt="Umbreon" />
        <span>Амбрион живет здесь в IT CRM</span>
      </div>
      <style>{`
        .umbreon-marquee {
          position: fixed;
          bottom: 0;
          width: 100%;
          display: flex;
          align-items: center;
          animation: marquee 15s linear infinite;
          white-space: nowrap;
          font-size: 1.5rem;
          color: #ffeb3b;
          z-index: 10;
        }

        .umbreon-marquee img {
          width: 50px;
          margin-right: 10px;
        }

        @keyframes marquee {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </div>
  );
};

export default Register;
