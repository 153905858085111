import React from "react";
import FrameCompany from "../../FrameCompany"; // Импорт компонента FrameCompany
import "./style.css"; // Ваши стили

export const Company = () => {
  return (
    <div className="home-page">
      <FrameCompany /> {/* Форма по центру */}
    </div>
  );
};

export default Company;
