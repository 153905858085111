import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spinner } from "@nextui-org/react";
import axios from 'axios';

const TaskPage = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const [task, setTask] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [deferModalVisible, setDeferModalVisible] = useState(false);
  const [deferDate, setDeferDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState('');

  const BASE_URL = 'https://итоуцоурце24.рф/api';

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const taskResponse = await axios.get(`${BASE_URL}/tasks/${taskId}/`, config);
        setTask(taskResponse.data);
      } catch (error) {
        console.error('Error fetching task details:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchComments = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        const commentsResponse = await axios.get(`${BASE_URL}/tasks/${taskId}/comments/`, config);
        setComments(commentsResponse.data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchTaskDetails();
    fetchComments();
  }, [taskId]);

  const handleAddComment = async () => {
    if (!newComment.trim()) return;
    try {
      const accessToken = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await axios.post(`${BASE_URL}/tasks/${taskId}/comments/`, {
        text: newComment,
      }, config);
      setComments([...comments, response.data]);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error.response?.data || error.message);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAddComment();
    }
  };

  const handleCompleteTask = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      await axios.patch(`${BASE_URL}/tasks/${taskId}/`, { status: 'closed' }, config);
      setTask((prevTask) => ({ ...prevTask, status: 'closed' }));
    } catch (error) {
      console.error('Error completing task:', error.response?.data || error.message);
    }
  };

  const handleOpenTask = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      await axios.patch(`${BASE_URL}/tasks/${taskId}/`, { status: 'open' }, config);
      setTask((prevTask) => ({ ...prevTask, status: 'open' }));
    } catch (error) {
      console.error('Error opening task:', error.response?.data || error.message);
    }
  };

  const handleDeferTask = async () => {
    if (!deferDate) return;
    try {
      const accessToken = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      await axios.patch(`${BASE_URL}/tasks/${taskId}/`, { status: 'deferred', defer_date: deferDate }, config);
      setTask((prevTask) => ({ ...prevTask, status: 'deferred', defer_date: deferDate }));
      setDeferModalVisible(false);
    } catch (error) {
      console.error('Error deferring task:', error.response?.data || error.message);
    }
  };

  const handleEditComment = (commentId, text) => {
    setEditingCommentId(commentId);
    setEditedCommentText(text);
  };

  const handleSaveEditedComment = async () => {
    if (!editedCommentText.trim()) return;
    try {
      const accessToken = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      await axios.patch(`${BASE_URL}/tasks/${taskId}/comments/${editingCommentId}/`, {
        text: editedCommentText,
      }, config);
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === editingCommentId ? { ...comment, text: editedCommentText } : comment
        )
      );
      setEditingCommentId(null);
      setEditedCommentText('');
    } catch (error) {
      console.error('Error editing comment:', error.response?.data || error.message);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      await axios.delete(`${BASE_URL}/tasks/${taskId}/comments/${commentId}/`, config);
      setComments((prevComments) => prevComments.filter((comment) => comment.id !== commentId));
    } catch (error) {
      console.error('Error deleting comment:', error.response?.data || error.message);
    }
  };

  if (loading) return <Spinner size="lg" />;
  if (!task) return <div style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>Задача не найдена</div>;

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', color: 'white' }}>
      <div style={{ padding: '20px', marginBottom: '20px', backgroundColor: '#333', borderRadius: '8px' }}>
        <h2>Задача #{task.id}</h2>
        <h3>Компания: {task.company?.name || 'Не указана'}</h3>
        <p>Статус: {task.status}</p>
        <p>Описание: {task.description}</p>
        {task.status === 'deferred' && task.defer_date && (
          <p>Отложена до: {new Date(task.defer_date).toLocaleString()}</p>
        )}
        {task.closed_by && <p>Закрыта: {task.closed_by}</p>}
        {task.deferred_by && <p>Отложена: {task.deferred_by}</p>}
      </div>

      <div style={{ marginTop: '20px' }}>
        {task.company && (
          <button onClick={() => navigate(`/companies/${task.company.id}`)} style={{ marginRight: '10px', padding: '10px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px' }}>Перейти в компанию</button>
        )}
        {task.status === 'open' && (
          <button onClick={handleCompleteTask} style={{ marginRight: '10px', padding: '10px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px' }}>Завершить</button>
        )}
        {task.status === 'deferred' && (
          <button onClick={handleOpenTask} style={{ marginRight: '10px', padding: '10px', backgroundColor: '#ffc107', color: 'black', border: 'none', borderRadius: '5px' }}>Открыть задачу</button>
        )}
        {task.status === 'closed' && (
          <button onClick={handleOpenTask} style={{ marginRight: '10px', padding: '10px', backgroundColor: '#ffc107', color: 'black', border: 'none', borderRadius: '5px' }}>Открыть задачу</button>
        )}
        <button onClick={() => setDeferModalVisible(true)} style={{ padding: '10px', backgroundColor: '#6c757d', color: 'white', border: 'none', borderRadius: '5px' }}>Отложить</button>
      </div>

      {deferModalVisible && (
        <div style={{ backgroundColor: '#444', padding: '20px', marginTop: '20px', borderRadius: '8px' }}>
          <h4>Отложить задачу</h4>
          <input
            type="datetime-local"
            value={deferDate}
            onChange={(e) => setDeferDate(e.target.value)}
            style={{ width: '100%', marginBottom: '10px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
          <button onClick={handleDeferTask} style={{ marginRight: '10px', padding: '10px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px' }}>Отложить</button>
          <button onClick={() => setDeferModalVisible(false)} style={{ padding: '10px', backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '5px' }}>Отмена</button>
        </div>
      )}

      <div style={{ padding: '20px', marginTop: '20px', backgroundColor: '#333', borderRadius: '8px' }}>
        <h3>Комментарии</h3>
        <div>
          {comments.length === 0 ? (
            <p>Нет комментариев</p>
          ) : (
            comments.map((comment) => (
              <div
                key={comment.id}
                style={{ marginBottom: '10px', padding: '10px', backgroundColor: '#444', borderRadius: '5px', position: 'relative' }}
                onDoubleClick={() => handleEditComment(comment.id, comment.text)}
              >
                {editingCommentId === comment.id ? (
                  <div>
                    <input
                      type="text"
                      value={editedCommentText}
                      onChange={(e) => setEditedCommentText(e.target.value)}
                      onKeyDown={(e) => e.key === 'Enter' && handleSaveEditedComment()}
                      style={{ width: 'calc(100% - 80px)', marginBottom: '10px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                    />
                    <button onClick={handleSaveEditedComment} style={{ padding: '5px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px', marginRight: '5px' }}>Сохранить</button>
                    <button onClick={() => setEditingCommentId(null)} style={{ padding: '5px', backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '5px' }}>Отмена</button>
                  </div>
                ) : (
                  <p>
                    <strong>{comment.author || 'Неизвестный автор'}</strong> ({new Date(comment.created_at).toLocaleString()}): {comment.text}
                  </p>
                )}
                <div className="comment-actions" style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', gap: '5px' }}>
                  <button onClick={() => handleEditComment(comment.id, comment.text)} style={{ padding: '5px', backgroundColor: 'transparent', color: 'white', border: 'none', cursor: 'pointer' }}>✏️</button>
                  <button onClick={() => handleDeleteComment(comment.id)} style={{ padding: '5px', backgroundColor: 'transparent', color: 'white', border: 'none', cursor: 'pointer' }}>🗑️</button>
                </div>
              </div>
            ))
          )}
        </div>
        <div style={{ marginTop: '20px' }}>
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Добавить комментарий..."
            style={{ width: 'calc(100% - 20px)', marginBottom: '10px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
          <button onClick={handleAddComment} style={{ padding: '10px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px' }}>Добавить комментарий</button>
        </div>
      </div>
    </div>
  );
};

export default TaskPage;
