import React from "react";
import FrameCompanies from '../../FrameCompanies';
import "./style.css"; // Ваши стили

export const Companies = () => {
  return (
    <div className="home-page">
      <FrameCompanies /> {/* Форма по центру */}
    </div>
  );
};

export default Companies;
