import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const KnowledgeBase = () => {
  const { id } = useParams();  // Используем id компании
  const [knowledgeItems, setKnowledgeItems] = useState([]);

  useEffect(() => {
    axios.get(`http://194.135.36.106:8000/api/companies/${id}/knowledge/`)
      .then(response => setKnowledgeItems(response.data))
      .catch(error => console.error("Ошибка при получении данных базы знаний:", error));
  }, [id]);

  return (
    <div>
      <h2>База знаний</h2>
      {knowledgeItems.length > 0 ? (
        <ul>
          {knowledgeItems.map(item => (
            <li key={item.id}>
              <strong>{item.title}</strong>
              <p>{item.content}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>Нет данных для отображения</p>
      )}
    </div>
  );
};

export default KnowledgeBase;
