// src/App.jsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import Companies from './pages/Companies/Companies';
import Reports from './pages/Reports/Reports';
import Cash from './pages/Cash/Cash';
import Login from './pages/Login/Login';
import Company from './pages/Company/Company';
import TaskPage from './FrameTask/FrameTask';
import Register from './pages/Register/Register';
import Layout from './components/Layout/Layout';
import AutoLogin from './AutoLogin';
import KnowledgeBase from './components/KnowledgeBase/KnowledgeBase';
import PriceList from './components/PriceList';
import ChatPage from './pages/ChatPage/ChatPage'; // Добавлен импорт ChatPage
import PrivateRoute from './components/PrivateRoute'; // Импорт PrivateRoute

const App = () => {
  return (
    <Routes>
      {/* Маршруты для неаутентифицированных пользователей */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />

      {/* Защищённые маршруты */}
      <Route element={<PrivateRoute />}>
        <Route element={<Layout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/companies/:id" element={<Company />} />
          <Route path="/tasks/:taskId" element={<TaskPage />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/companies/:id/knowledge-base" element={<KnowledgeBase />} />
          <Route path="/auto-login" element={<AutoLogin />} />
          <Route path="/companies/:id/price-list" element={<PriceList />} />
          <Route path="/cash" element={<Cash />} />
          <Route path="/chat" element={<ChatPage />} /> {/* Добавлен маршрут для ChatPage */}
          <Route path="*" element={<Navigate to="/home" />} />
        </Route>
      </Route>

      {/* Перенаправление всех остальных маршрутов на страницу входа */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default App;
