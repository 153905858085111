import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AutoLogin() {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (token) {
            localStorage.setItem('accessToken', token);
            navigate('/home');
        } else {
            navigate('/login');
        }
    }, [navigate]);

    return null;
}

export default AutoLogin;
