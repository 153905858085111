// src/FrameReports/FrameReports.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import printerIcon from "./printer.png";
import downloadIcon from "./download.png";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Маппинг статусов задач
const statusMapping = {
  open: { label: "Открыта", className: "open" },
  deferred: { label: "Отложена", className: "deferred" },
  closed: { label: "Закрыта", className: "closed" },
  in_progress: { label: "В процессе", className: "in-progress" },
  // Добавьте другие статусы по необходимости
};

export const FrameReports = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedCompany, setSelectedCompany] = useState("");
  const [totalSum, setTotalSum] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Функция для получения токена из localStorage
  const getToken = () => {
    try {
      return typeof window !== "undefined" && window.localStorage
        ? localStorage.getItem("accessToken")
        : null;
    } catch (e) {
      console.error("Доступ к localStorage не разрешен:", e);
      return null;
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const token = getToken();
      if (!token) {
        setError("Нет токена авторизации");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get("https://итоуцоурце24.рф/api/companies/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setCompanies(response.data);
      } catch (error) {
        console.error("Ошибка при получении компаний:", error);
        setError("Ошибка при получении компаний");
        setLoading(false);
      }
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (!selectedCompany) {
      setTasks([]);
      setTotalSum(0);
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      const token = getToken();
      if (!token) {
        setError("Нет токена авторизации");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`https://итоуцоурце24.рф/api/companies/${selectedCompany}/tasks/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });

        const filteredTasks = response.data.filter((task) => {
          const taskDate = new Date(task.created_at);
          return (
            taskDate.getMonth() === selectedMonth.getMonth() &&
            taskDate.getFullYear() === selectedMonth.getFullYear() &&
            task.status === "closed" // Фильтруем только закрытые задачи
          );
        });

        setTasks(filteredTasks);
        setTotalSum(filteredTasks.reduce((acc, task) => acc + (task.price || 0), 0));
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
        setError("Ошибка при получении задач компании");
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedMonth, selectedCompany]);

  const generatePDF = () => {
    if (!selectedCompany) {
      alert("Пожалуйста, выберите компанию.");
      return;
    }

    const company = companies.find(comp => comp.id === parseInt(selectedCompany, 10));
    if (!company) {
      alert("Компания не найдена.");
      return;
    }

    const lastDay = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).toLocaleDateString("ru-RU");
    const selectedMonthName = selectedMonth.toLocaleString("ru-RU", { month: "long", year: "numeric" });

    const documentDefinition = {
      content: [
        { text: "АКТ ОБ ОКАЗАНИИ УСЛУГ", style: "header", alignment: "center" },
        {
          columns: [
            { text: `г. Москва`, alignment: "left" },
            { text: `${lastDay}`, alignment: "right" }
          ],
          margin: [0, 20]
        },
        {
          text: `Индивидуальный предприниматель Мазалов Олег Дмитриевич, ИНН 224700661192, именуемый в дальнейшем Исполнитель, с одной стороны, и ${company.name}, именуемое в дальнейшем Заказчик, в лице представителя ${company.contactPerson || "компании"}, действующего на основании Устава, с другой стороны, вместе именуемые в дальнейшем «Стороны», составили настоящий Акт о нижеследующем:`,
          margin: [0, 10]
        },
        {
          text: "1. Исполнитель по заданию Заказчика оказал, а Заказчик принял следующие услуги:",
          margin: [0, 10]
        },
        {
          table: {
            headerRows: 1,
            widths: ["auto", "*", "auto", "auto", "auto", "auto"],
            body: [
              ["№", "Услуги", "Кол-во", "Ед.", "Цена", "Сумма"],
              ...tasks.map((task, index) => [
                index + 1,
                task.description || "Описание отсутствует",
                task.quantity || 1,
                "шт.",
                `${task.price} ₽`,
                `${(task.price || 0) * (task.quantity || 1)} ₽`
              ])
            ]
          },
          margin: [0, 10]
        },
        {
          text: `Всего наименований ${tasks.length}, на сумму ${totalSum.toFixed(2).replace('.', ',')} руб. ${convertNumberToWords(totalSum)}.`,
          style: "totalBold",
          margin: [0, 10]
        },
        {
          text: "2. Заказчик не имеет претензий к Исполнителю по качеству, объему и срокам оказания услуг.",
          margin: [0, 10]
        },
        {
          text: `3. Заказчик подтверждает, что оказанные Исполнителем услуги соответствуют заявкам Заказчика за ${selectedMonthName}.`,
          margin: [0, 10]
        },
        {
          columns: [
            { text: "Исполнитель: ______________", margin: [0, 100] },
            { text: "Заказчик: ______________", alignment: "right", margin: [0, 100] }
          ]
        }
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true
        },
        totalBold: {
          fontSize: 12,
          bold: true
        }
      }
    };

    pdfMake.createPdf(documentDefinition).open();
  };

  const convertNumberToWords = (num) => {
    const ones = ["", "один", "два", "три", "четыре", "пять", "шесть", "семь", "восемь", "девять"];
    const teens = ["", "одиннадцать", "двенадцать", "тринадцать", "четырнадцать", "пятнадцать", "шестнадцать", "семнадцать", "восемнадцать", "девятнадцать"];
    const tens = ["", "десять", "двадцать", "тридцать", "сорок", "пятьдесят", "шестьдесят", "семьдесят", "восемьдесят", "девяносто"];
    const hundreds = ["", "сто", "двести", "триста", "четыреста", "пятьсот", "шестьсот", "семьсот", "восемьсот"];
    const thousands = ["", "тысяча", "тысячи", "тысяч"];

    if (num === 0) return "ноль рублей ноль копеек";

    let rub = Math.floor(num);
    const kop = Math.round((num - rub) * 100);

    let words = [];

    if (rub >= 1000) {
      const thousand = Math.floor(rub / 1000);
      words.push(getThousandWords(thousand));
      rub %= 1000;
    }

    if (rub >= 100) {
      words.push(hundreds[Math.floor(rub / 100)]);
      rub %= 100;
    }

    if (rub >= 20) {
      words.push(tens[Math.floor(rub / 10)]);
      rub %= 10;
    } else if (rub >= 11 && rub <= 19) {
      words.push(teens[rub - 10]);
      rub = 0;
    }

    if (rub > 0) {
      words.push(ones[rub]);
    }

    const rubWord = getRubWord(rub);
    const kopWord = getKopWord(kop);

    return `${words.join(" ")} ${rubWord} ${kopWord}`;
  };

  const getThousandWords = (num) => {
    const ones = ["", "одна", "две", "три", "четыре", "пять", "шесть", "семь", "восемь", "девять"];
    const teens = ["", "одиннадцать", "двенадцать", "тринадцать", "четырнадцать", "пятнадцать", "шестнадцать", "семнадцать", "восемнадцать", "девятнадцать"];
    const tens = ["", "десять", "двадцать", "тридцать", "сорок", "пятьдесят", "шестьдесят", "семьдесят", "восемьдесят", "девяносто"];
    const hundreds = ["", "сто", "двести", "триста", "четыреста", "пятьсот", "шестьсот", "семьсот", "восемьсот"];
    const thousands = ["", "тысяча", "тысячи", "тысяч"];

    let words = [];

    if (num >= 100) {
      words.push(hundreds[Math.floor(num / 100)]);
      num %= 100;
    }

    if (num >= 20) {
      words.push(tens[Math.floor(num / 10)]);
      num %= 10;
    } else if (num >= 11 && num <= 19) {
      words.push(teens[num - 10]);
      num = 0;
    }

    if (num > 0 && num <= 9) {
      words.push(ones[num]);
    }

    let thousandIndex = 3; // по умолчанию "тысяч"
    if (num === 1) thousandIndex = 1;
    else if (num >= 2 && num <= 4) thousandIndex = 2;

    return `${words.join(" ")} ${thousands[thousandIndex]}`;
  };

  const getRubWord = (num) => {
    const lastDigit = num % 10;
    const lastTwoDigits = num % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return "рублей";
    }
    switch (lastDigit) {
      case 1:
        return "рубль";
      case 2:
      case 3:
      case 4:
        return "рубля";
      default:
        return "рублей";
    }
  };

  const getKopWord = (num) => {
    const lastDigit = num % 10;
    const lastTwoDigits = num % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return "копеек";
    }
    switch (lastDigit) {
      case 1:
        return "копейка";
      case 2:
      case 3:
      case 4:
        return "копейки";
      default:
        return "копеек";
    }
  };

  return (
    <div className="frame-companies-centered">
      <div className="search-field">
        <DatePicker
          selected={selectedMonth}
          onChange={(date) => setSelectedMonth(date)}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          className="search-input"
        />
        <select
          value={selectedCompany}
          onChange={(e) => setSelectedCompany(e.target.value)}
          className="search-input"
        >
          <option value="">Выберите компанию</option>
          {companies.map((company) => (
            <option key={company.id} value={company.id}>
              {company.name}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <p className="report-loading">Загрузка задач...</p>
      ) : error ? (
        <p className="report-error">Ошибка: {error}</p>
      ) : (
        <>
          <table className="report-table">
            <thead>
              <tr>
                <th>№ Задачи</th>
                <th>Услуга</th>
                <th>Стоимость</th>
                <th>Ответственный</th>
                <th>Статус</th>
              </tr>
            </thead>
            <tbody>
              {tasks.length > 0 ? (
                tasks.map((task) => (
                  <tr key={task.id}>
                    <td>{task.id}</td>
                    <td>{task.description}</td>
                    <td>{task.price} ₽</td>
                    <td>{task.responsible}</td>
                    <td className={`task-status ${statusMapping[task.status]?.className || "unknown"}`}>
                      {statusMapping[task.status]?.label || capitalizeFirstLetter(task.status)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">Нет задач для отображения</td>
                </tr>
              )}
              <tr>
                <td colSpan="2" className="total-label">Итого:</td>
                <td>{totalSum} ₽</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <div className="icons">
            <img src={printerIcon} alt="Print" onClick={generatePDF} className="icon" />
            <img src={downloadIcon} alt="Download" onClick={() => alert("Скачивание в PDF скоро будет доступно")} className="icon" />
          </div>
        </>
      )}
    </div>
  );
};

// Функция для преобразования первой буквы в верхний регистр
const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Обязательно экспортировать как именованный и дефолтный
export default FrameReports;
