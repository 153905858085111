import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
  const [user, setUser] = useState(null); // Состояние для пользователя
  const [loading, setLoading] = useState(true); // Состояние загрузки

  // Функция для логина и сохранения токенов
  const login = (access, refresh) => {
    setAccessToken(access);
    setRefreshToken(refresh);
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);
  };

  // Функция для выхода из системы и сброса данных
  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
    setUser(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  // Функция для получения данных пользователя
  const fetchUserData = async () => {
    if (!accessToken) {
      setUser(null);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get("https://итоуцоурце24.рф/api/user-details/", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true,
      });
      console.log("Ответ от API /user-details/:", response.data); // Лог для отладки
      setUser(response.data);
    } catch (error) {
      console.error("Ошибка при получении данных пользователя:", error);
      logout(); // Если токен недействителен, выполняем logout
    } finally {
      setLoading(false); // Завершаем состояние загрузки в любом случае
    }
  };

  // Функция для обновления токена доступа
  const refreshAccessToken = async () => {
    if (!refreshToken) {
      logout();
      return;
    }

    try {
      const response = await axios.post('https://итоуцоурце24.рф/api/token/refresh/', {
        refresh: refreshToken,
      });
      const newAccessToken = response.data.access;
      setAccessToken(newAccessToken);
      localStorage.setItem('accessToken', newAccessToken);
    } catch (error) {
      console.error('Ошибка при обновлении токена:', error);
      logout(); // Если обновление токена не удалось, выполняем logout
    }
  };

  // Автоматическое обновление токена перед его истечением
  useEffect(() => {
    if (!accessToken) return;

    // Декодирование JWT для получения времени истечения
    const jwtPayload = JSON.parse(atob(accessToken.split('.')[1]));
    const exp = jwtPayload.exp * 1000; // Перевод в миллисекунды

    const timeout = exp - Date.now() - (60 * 1000); // Обновление за 1 минуту до истечения

    if (timeout > 0) {
      const timer = setTimeout(() => {
        refreshAccessToken();
      }, timeout);

      return () => clearTimeout(timer);
    } else {
      // Если токен уже истек, сразу пытаемся обновить
      refreshAccessToken();
    }
  }, [accessToken, refreshToken]);

  // Получение данных пользователя при изменении токена доступа
  useEffect(() => {
    if (accessToken) {
      setLoading(true);
      fetchUserData();
    } else {
      setUser(null);
      setLoading(false);
    }
  }, [accessToken]);

  // Загрузка токенов из localStorage при инициализации
  useEffect(() => {
    setAccessToken(localStorage.getItem('accessToken'));
    setRefreshToken(localStorage.getItem('refreshToken'));
  }, []);

  return (
    <AuthContext.Provider value={{ accessToken, refreshToken, login, logout, user, loading }}>
      {children}
    </AuthContext.Provider>
  );
}
