// src/pages/ChatPage/ChatPage.jsx

import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const ChatContainer = styled.div`
  padding: 2rem;
  background-color: #2e2e2e;
  color: #ffffff;
  min-height: 100vh;
`;

const MessagesContainer = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  margin-bottom: 1rem;
`;

const Message = styled.div`
  background-color: ${(props) => (props.sender === 'user' ? '#4CAF50' : '#555')};
  color: #ffffff;
  padding: 0.8rem;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  max-width: 70%;
  align-self: ${(props) => (props.sender === 'user' ? 'flex-end' : 'flex-start')};
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ChatInput = styled.input`
  flex: 1;
  padding: 0.8rem;
  border-radius: 8px;
  border: none;
  margin-right: 0.5rem;
  background: #444;
  color: #ffffff;
  outline: none;

  &::placeholder {
    color: #c1c1c1;
  }
`;

const SendButton = styled.button`
  background: #4CAF50;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 0.8rem 1rem;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #45a049;
  }
`;

const ChatPage = () => {
  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Привет! Я здесь, чтобы помочь. Чем могу быть полезен?' },
  ]);
  const [input, setInput] = useState('');

  const getToken = () => {
    try {
      return typeof window !== "undefined" && window.localStorage ? localStorage.getItem("accessToken") : null;
    } catch (e) {
      console.error("Доступ к localStorage не разрешен:", e);
      return null;
    }
  };

  const sendMessage = async () => {
    if (input.trim() === '') return;

    const userMessage = { sender: 'user', text: input };
    setMessages([...messages, userMessage]);

    try {
      const token = getToken();
      const response = await axios.post(
        'http://194.135.36.106:8000/api/chat/',
        { message: input },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        }
      );

      const botMessage = { sender: 'bot', text: response.data.response };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Ошибка при отправке сообщения:', error);
      const errorMessage = { sender: 'bot', text: 'Произошла ошибка при обработке вашего сообщения.' };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }

    setInput('');
  };

  return (
    <ChatContainer>
      <h2>Чат с моделью</h2>
      <MessagesContainer>
        {messages.map((msg, index) => (
          <Message key={index} sender={msg.sender}>
            <strong>{msg.sender === 'user' ? 'Вы' : 'Модель'}:</strong> {msg.text}
          </Message>
        ))}
      </MessagesContainer>
      <InputContainer>
        <ChatInput
          type="text"
          placeholder="Введите сообщение..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => { if (e.key === 'Enter') sendMessage(); }}
        />
        <SendButton onClick={sendMessage}>Отправить</SendButton>
      </InputContainer>
    </ChatContainer>
  );
};

export default ChatPage;
