// src/pages/Home/Home.jsx
import React from 'react';
// src/pages/Home/Home.jsx
import FrameOpenTasks from '../../FrameOpenTasks/FrameOpenTasks';

const Home = () => {
  return (
    <>
      <FrameOpenTasks /> {/* Форма по центру */}
    </>
  );
};

export default Home;
