// src/components/FrameCompany.jsx

import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './style.css';

const FrameCompany = () => {
  const { id } = useParams();
  const [company, setCompany] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [showPriceForm, setShowPriceForm] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState('');
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [newServiceName, setNewServiceName] = useState('');
  const [newServicePrice, setNewServicePrice] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const modalRef = useRef(null);

  // Маппинг статусов задач
  const statusMapping = {
    open: { label: "Открыта", className: "open" },
    deferred: { label: "Ожидает", className: "awaiting" },
    closed: { label: "Закрыта", className: "closed" },
    in_progress: { label: "В процессе", className: "in-progress" },
    // Добавьте другие статусы по необходимости
  };

  // Функция для получения токена из localStorage
  const getToken = () => {
    try {
      return typeof window !== "undefined" && window.localStorage
        ? localStorage.getItem("accessToken")
        : null;
    } catch (e) {
      console.error("Доступ к localStorage не разрешен:", e);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = getToken();
      if (!token) {
        setError("Нет токена авторизации");
        setLoading(false);
        return;
      }

      try {
        // Параллельные запросы к API
        const [companyResponse, tasksResponse, priceListResponse] = await Promise.all([
          axios.get(`https://итоуцоурце24.рф/api/companies/${id}/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }),
          axios.get(`https://итоуцоурце24.рф/api/companies/${id}/tasks/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }),
          axios.get(`https://итоуцоурце24.рф/api/companies/${id}/price-list/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }),
        ]);

        setCompany(companyResponse.data);
        setTasks(tasksResponse.data);
        setPriceList(priceListResponse.data);
        setLoading(false);
      } catch (err) {
        console.error("Ошибка при получении данных:", err);
        setError("Ошибка при получении данных компании");
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <div className="frame-company">Загрузка...</div>;
  if (error) return <div className="frame-company error">Ошибка: {error}</div>;

  const handleTaskClick = (taskId) => {
    navigate(`/tasks/${taskId}`);
  };

  const handleCreateTask = () => {
    setShowTaskForm(true);
  };

  const handleTaskFormSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();
    if (!token) {
      alert("Нет токена авторизации.");
      return;
    }

    try {
      const response = await axios.post(
        `https://итоуцоурце24.рф/api/companies/${id}/tasks/`,
        {
          title: newTaskTitle,
          description: newTaskDescription
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      setTasks([...tasks, response.data]);
      setShowTaskForm(false);
      setNewTaskTitle('');
      setNewTaskDescription('');
    } catch (error) {
      console.error("Ошибка при создании задачи:", error);
      alert("Произошла ошибка при создании задачи.");
    }
  };

  const handlePriceFormSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();
    if (!token) {
      alert("Нет токена авторизации.");
      return;
    }

    try {
      const response = await axios.post(
        `https://итоуцоурце24.рф/api/companies/${id}/price-list/`,
        {
          service_name: newServiceName,
          price: newServicePrice
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      setPriceList([...priceList, response.data]);
      setShowPriceForm(false);
      setNewServiceName('');
      setNewServicePrice('');
    } catch (error) {
      console.error("Ошибка при создании услуги:", error);
      alert("Произошла ошибка при создании услуги.");
    }
  };

  const handleKnowledgeBaseClick = () => {
    navigate(`/companies/${id}/knowledge-base`);
  };

  // Закрытие формы при клике вне области формы
  const handleOverlayClick = (e) => {
    if (e.target === modalRef.current) {
      setShowTaskForm(false);
      setShowPriceForm(false);
    }
  };

  return (
    <div className="frame-company">
      <h2 className="company-name">{company.name}</h2>
      <p className="company-description">{company.description}</p>

      <div className="button-container">
        <button onClick={handleCreateTask} className="btn">Создать задачу</button>
        <button onClick={() => setShowPriceForm(true)} className="btn">Добавить услугу</button>
        <button onClick={handleKnowledgeBaseClick} className="btn">База знаний</button>
      </div>

      <h3 className="section-title">Задачи компании:</h3>
      {tasks.length === 0 ? (
        <p>Нет задач для отображения.</p>
      ) : (
        <ul className="task-list">
          {tasks.map(task => {
            const status = statusMapping[task.status] || { label: task.status, className: "unknown" };
            return (
              <li key={task.id} onClick={() => handleTaskClick(task.id)} className="task-item">
                <strong>{task.title}</strong>
                <p>{task.description}</p>
                <span className={`task-status ${status.className}`}>
                  {status.label === "Ожидает" && task.defer_date
                    ? `${status.label} до ${new Date(task.defer_date).toLocaleString()}`
                    : status.label}
                </span>
              </li>
            );
          })}
        </ul>
      )}

      <h3 className="section-title">Прайс-лист компании:</h3>
      {priceList.length === 0 ? (
        <p>Нет услуг в прайс-листе.</p>
      ) : (
        <ul className="price-list">
          {priceList.map(item => (
            <li key={item.id} className="price-item">
              <strong>{item.service_name}</strong> - {item.price} руб.
            </li>
          ))}
        </ul>
      )}

      {showTaskForm && (
        <div className="modal-overlay" onClick={handleOverlayClick} ref={modalRef}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h4>Создать задачу</h4>
            <form onSubmit={handleTaskFormSubmit} className="modal-form">
              <input
                type="text"
                placeholder="Название задачи"
                value={newTaskTitle}
                onChange={(e) => setNewTaskTitle(e.target.value)}
                required
              />
              <textarea
                placeholder="Описание задачи"
                value={newTaskDescription}
                onChange={(e) => setNewTaskDescription(e.target.value)}
                required
                rows={4}
              />
              <button type="submit" className="btn">Создать</button>
              <button type="button" onClick={() => setShowTaskForm(false)} className="btn cancel">Отмена</button>
            </form>
          </div>
        </div>
      )}

      {showPriceForm && (
        <div className="modal-overlay" onClick={handleOverlayClick} ref={modalRef}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h4>Добавить услугу</h4>
            <form onSubmit={handlePriceFormSubmit} className="modal-form">
              <input
                type="text"
                placeholder="Название услуги"
                value={newServiceName}
                onChange={(e) => setNewServiceName(e.target.value)}
                required
              />
              <input
                type="number"
                placeholder="Цена услуги"
                value={newServicePrice}
                onChange={(e) => setNewServicePrice(e.target.value)}
                required
                min="0"
                step="0.01"
              />
              <button type="submit" className="btn">Добавить</button>
              <button type="button" onClick={() => setShowPriceForm(false)} className="btn cancel">Отмена</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

// Маппинг статусов можно вынести за компонент, если он нужен в других местах
export default FrameCompany;
