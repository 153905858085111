// src/index.jsx
import React from 'react';
import ReactDOM from 'react-dom/client'; // Для React 18
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './index.css';
import { NextUIProvider } from '@nextui-org/react';
import { AuthProvider } from './AuthContext'; // Импортируйте AuthProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NextUIProvider>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </NextUIProvider>
  </React.StrictMode>
);
