// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame-cash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  max-width: 900px;
  width: 100%;
  margin-left: 600px;
  margin-right: auto;
  position: relative;
  top: -800px; /* Поднимаем блок вверх */
  z-index: 5;
}

.header {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.card {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  color: white;
  width: 40%;
}

.card h2 {
  font-size: 24px;
  margin: 0;
}

.card p {
  margin: 5px 0 0;
}

.table-container {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
}

.table-header, .table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  color: white;
}

.table-header {
  font-weight: bold;
  border-bottom: 2px solid #61425e;
}

.table-row {
  border-bottom: 1px solid #61425e;
}

.table-row span {
  flex: 1 1;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/FrameCash/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,oCAAoC;EACpC,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW,EAAE,yBAAyB;EACtC,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,SAAO;EACP,kBAAkB;AACpB","sourcesContent":[".frame-cash-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  background-color: rgba(0, 0, 0, 0.8);\n  border-radius: 10px;\n  max-width: 900px;\n  width: 100%;\n  margin-left: 600px;\n  margin-right: auto;\n  position: relative;\n  top: -800px; /* Поднимаем блок вверх */\n  z-index: 5;\n}\n\n.header {\n  display: flex;\n  justify-content: space-around;\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n.card {\n  background-color: rgba(0, 0, 0, 0.7);\n  border-radius: 10px;\n  padding: 20px;\n  text-align: center;\n  color: white;\n  width: 40%;\n}\n\n.card h2 {\n  font-size: 24px;\n  margin: 0;\n}\n\n.card p {\n  margin: 5px 0 0;\n}\n\n.table-container {\n  background-color: rgba(0, 0, 0, 0.7);\n  border-radius: 10px;\n  padding: 20px;\n  width: 100%;\n}\n\n.table-header, .table-row {\n  display: flex;\n  justify-content: space-between;\n  padding: 10px 0;\n  color: white;\n}\n\n.table-header {\n  font-weight: bold;\n  border-bottom: 2px solid #61425e;\n}\n\n.table-row {\n  border-bottom: 1px solid #61425e;\n}\n\n.table-row span {\n  flex: 1;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
