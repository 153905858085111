import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const PriceList = () => {
    const { id } = useParams(); // Извлечение id компании из параметров маршрута
    const [priceLists, setPriceLists] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPriceLists = async () => {
            try {
                const response = await axios.get(`/companies/${id}/price-list/`); // Использование id
                setPriceLists(response.data);
            } catch (err) {
                setError('Не удалось загрузить данные прайс-листа.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchPriceLists();
    }, [id]);

    if (loading) {
        return <p>Загрузка...</p>;
    }

    return (
        <div>
            <h1>Прайс-лист</h1>
            {error ? (
                <p>{error}</p>
            ) : (
                <ul>
                    {priceLists.map((priceList) => (
                        <li key={priceList.id}>
                            {priceList.service}: {priceList.price} ₽
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default PriceList;
