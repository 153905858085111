// src/components/DesktopVertical.jsx

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./style.css";

// Стили для компонентов остаются без изменений

const FrameOpenTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [companies, setCompanies] = useState({});
  const [loading, setLoading] = useState(true); // Добавлено состояние загрузки
  const [error, setError] = useState(null); // Добавлено состояние ошибки
  const navigate = useNavigate();

  // Функция для получения токена из localStorage
  const getToken = () => {
    try {
      return typeof window !== "undefined" && window.localStorage
        ? localStorage.getItem("accessToken")
        : null;
    } catch (e) {
      console.error("Доступ к localStorage не разрешен:", e);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = getToken();
      if (!token) {
        setError("Нет токена авторизации");
        setLoading(false);
        return;
      }

      try {
        // Параллельный запрос задач и компаний
        const [tasksResponse, companiesResponse] = await Promise.all([
          axios.get("https://итоуцоурце24.рф/api/tasks/", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }),
          axios.get("https://итоуцоурце24.рф/api/companies/", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }),
        ]);

        // Фильтрация задач по статусу
        const filteredTasks = tasksResponse.data.filter(
          (task) => task.status === "open" || task.status === "deferred"
        );
        setTasks(filteredTasks);

        // Создание мапы компаний для быстрого доступа
        const companiesData = companiesResponse.data.reduce((acc, company) => {
          acc[company.id] = company.name;
          return acc;
        }, {});
        setCompanies(companiesData);

        setLoading(false);
      } catch (err) {
        console.error("Ошибка при получении данных:", err);
        setError("Ошибка при получении данных");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTaskClick = (taskId) => {
    navigate(`/tasks/${taskId}`);
  };

  if (loading) {
    return <div className="frame-tasks-container">Загрузка задач...</div>;
  }

  if (error) {
    return <div className="frame-tasks-container">Ошибка: {error}</div>;
  }

  return (
    <div className="frame-tasks-container">
      {tasks.length === 0 ? (
        <div>Нет открытых задач.</div>
      ) : (
        tasks.map((task) => (
          <div
            key={task.id}
            className="task-card"
            onClick={() => handleTaskClick(task.id)}
          >
            <div className="task-company">
              {task.company && task.company.id
                ? companies[task.company.id] || "Неизвестная компания"
                : "Неизвестная компания"}
            </div>
            <div
              className={`task-status ${
                task.status === "deferred" ? "awaiting" : "open"
              }`}
            >
              {task.status === "deferred"
                ? `Ожидает до ${
                    task.defer_date
                      ? new Date(task.defer_date).toLocaleString()
                      : ""
                  }`
                : "Открыта"}
            </div>
            <div className="task-title">{`Задача ${task.id}: ${task.title}`}</div>
            <div className="task-description">{task.description}</div>
            <div className="task-created-at">
              Дата создания:{" "}
              {task.created_at
                ? new Date(task.created_at).toLocaleDateString()
                : "Неизвестна"}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default FrameOpenTasks;
