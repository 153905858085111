// src/components/DesktopVertical.jsx

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaHome, FaBuilding, FaChartBar, FaCog, FaSignOutAlt, FaBell, FaComments } from "react-icons/fa";
import styled from "styled-components";
import axios from "axios";

// Стили для компонентов
const Sidebar = styled.div`
  background: linear-gradient(135deg, #1f1c2c, #928dab);
  padding: 2rem 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  z-index: 10;
`;

const Content = styled.div`
  margin-left: 260px;
  padding: 2rem;
  background-color: #2e2e2e;
  min-height: 100vh;
`;

const Logo = styled.h1`
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: #ff6b6b;
  }
`;

const UserName = styled.p`
  font-size: 1.1rem;
  color: #dcdcdc;
  text-align: center;
  margin-bottom: 2rem;
`;

const NavLink = styled(Link)`
  color: ${(props) => (props.active === "true" ? "#4CAF50" : "#ffffff")};
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 8px;
  margin: 0.3rem 0;
  font-size: 1rem;
  background: ${(props) => (props.active === "true" ? "rgba(255, 255, 255, 0.2)" : "transparent")};
  transition: background 0.3s ease, transform 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateX(5px);
  }

  svg {
    margin-right: 0.7rem;
  }
`;

const Notifications = styled.div`
  color: #ffffff;
  cursor: pointer;
  margin-top: 1rem;
  text-align: left;
  width: 100%;
`;

const NotificationList = styled.ul`
  list-style: none;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  color: #c1c1c1;
  font-size: 0.9rem;
`;

const SearchInput = styled.input`
  width: 90%;
  padding: 0.8rem;
  border-radius: 8px;
  border: none;
  margin-top: 1.5rem;
  background: rgba(255, 255, 255, 0.15);
  color: #ffffff;
  outline: none;
  transition: background 0.3s ease;

  &::placeholder {
    color: #c1c1c1;
  }

  &:focus {
    background: rgba(255, 255, 255, 0.25);
  }
`;

const SearchResults = styled.div`
  width: 90%;
  background: #444;
  color: #fff;
  margin-top: 0.5rem;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  padding: 0.5rem;
`;

const ResultItem = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid #555;
  &:hover {
    background: #333;
  }
`;

const LogoutButton = styled.button`
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 0.8rem 1rem;
  margin-top: auto;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  width: 100%;
  text-align: center;

  &:hover {
    background: #ff6b6b;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const DesktopVertical = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userName, setUserName] = useState("Загрузка...");
  const [notifications] = useState([
    "Уведомление 1: Новое сообщение в компании",
    "Уведомление 2: Обновление задачи",
    "Уведомление 3: Задача завершена",
  ]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const getToken = () => {
    try {
      return typeof window !== "undefined" && window.localStorage
        ? localStorage.getItem("accessToken")
        : null;
    } catch (e) {
      console.error("Доступ к localStorage не разрешен:", e);
      return null;
    }
  };

  const fetchUserData = async () => {
    const token = getToken();
    if (token) {
      try {
        const response = await axios.get("https://итоуцоурце24.рф/api/user-details/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        console.log("Ответ от API /user-details/:", response.data); // Добавлено для отладки
  
        if (Array.isArray(response.data) && response.data.length > 0) {
          const user = response.data[0];
          const { first_name, last_name, username } = user;
          const fullName = `${first_name || ''} ${last_name || ''}`.trim() || username || "Пользователь";
          setUserName(fullName);
        } else {
          setUserName("Неизвестный пользователь");
        }
      } catch (error) {
        console.error("Ошибка при получении данных пользователя:", error);
        setUserName("Неизвестный пользователь");
      }
    } else {
      setUserName("Неавторизованный");
    }
  };
  

  const handleSearchChange = async (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.length > 1) {
      try {
        const response = await axios.get(`https://итоуцоурце24.рф/api/search/?query=${query}`);
        const { companies, tasks } = response.data;

        const combinedResults = [
          ...companies.map((company) => ({ ...company, type: "company" })),
          ...tasks.map((task) => ({ ...task, type: "task" })),
        ];

        setSearchResults(combinedResults);
        console.log("Результаты поиска:", combinedResults);
      } catch (error) {
        console.error("Ошибка при поиске:", error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleResultClick = (result) => {
    if (result.type === "company") {
      navigate(`/companies/${result.id}`);
    } else if (result.type === "task") {
      navigate(`/tasks/${result.id}`);
    }
  };

  const handleLogout = () => {
    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }
    navigate("/login");
    window.location.reload();
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <>
      <Sidebar>
        <Logo>IT_CRM</Logo>
        <UserName>{userName}</UserName>

        <NavLink to="/home" active={location.pathname === "/home" ? "true" : undefined}>
          <FaHome size={20} /> Главная
        </NavLink>
        <NavLink to="/companies" active={location.pathname === "/companies" ? "true" : undefined}>
          <FaBuilding size={20} /> Компании
        </NavLink>
        <NavLink to="/reports" active={location.pathname === "/reports" ? "true" : undefined}>
          <FaChartBar size={20} /> Отчеты
        </NavLink>
        <NavLink to="/cash" active={location.pathname === "/cash" ? "true" : undefined}>
          <FaChartBar size={20} /> Зарплата
        </NavLink>
        {/* Изменён маршрут для "Настройки" на /admin/ */}
        <NavLink to="/admin/" active={location.pathname === "/admin/" ? "true" : undefined}>
          <FaCog size={20} /> Настройки
        </NavLink>
        {/* Новый пункт меню для чата */}
        <NavLink to="/chat" active={location.pathname === "/chat" ? "true" : undefined}>
          <FaComments size={20} /> Чат
        </NavLink>

        <Notifications>
          <FaBell size={20} /> Уведомления
          <NotificationList>
            {notifications.map((notification, index) => (
              <li key={index}>- {notification}</li>
            ))}
          </NotificationList>
        </Notifications>

        <SearchInput
          type="text"
          placeholder="Поиск по сайту"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        {searchResults.length > 0 && (
          <SearchResults>
            {searchResults.map((result) => (
              <ResultItem
                key={result.id}
                onClick={() => handleResultClick(result)}
              >
                {result.type === "company" ? `Компания: ${result.name}` : `Задача: ${result.title}`}
              </ResultItem>
            ))}
          </SearchResults>
        )}

        <LogoutButton onClick={handleLogout}>
          <FaSignOutAlt size={20} /> Выход
        </LogoutButton>
      </Sidebar>

      <Content>
        {/* Основной контент страницы */}
      </Content>
    </>
  );
};

export default DesktopVertical;
