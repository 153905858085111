// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
    display: flex;
    height: 100vh;
    background: #2e2e2e;
    overflow: hidden;
}

.layout-content {
    flex: 1 1;
    padding: 30px;
    margin-left: 0; /* Убрали лишний отступ */
    overflow-y: auto;
    position: relative;
    z-index: 2;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,cAAc,EAAE,yBAAyB;IACzC,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".layout {\n    display: flex;\n    height: 100vh;\n    background: #2e2e2e;\n    overflow: hidden;\n}\n\n.layout-content {\n    flex: 1;\n    padding: 30px;\n    margin-left: 0; /* Убрали лишний отступ */\n    overflow-y: auto;\n    position: relative;\n    z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
