import React from "react";
import { FrameCash } from "../../FrameCash"; // Импорт правильного компонента

import "./style.css"; // Ваши стили

const Cash = () => {
  return (
    <div className="home-page">
      <FrameCash /> {/* Меню слева */}

    </div>
  );
};

export default Cash;
