import React, { useEffect, useContext } from 'react';
import { Card } from '@nextui-org/react';
import { motion } from 'framer-motion';
import { AuthContext } from '../../AuthContext';

// Helper function to get CSRF token from cookies
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function Login() {
  const { login } = useContext(AuthContext);

  // Check for localStorage availability
  const isLocalStorageAvailable = () => {
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return true;
    } catch (error) {
      console.warn('localStorage is unavailable:', error);
      return false;
    }
  };

  // Telegram auth handler
  useEffect(() => {
    window.handleTelegramAuth = async (user) => {
      console.log("Telegram User Data:", user);

      try {
        const csrftoken = getCookie('csrftoken');

        const response = await fetch('https://итоуцоурце24.рф/api/telegram-login/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify(user),
        });

        console.log("Response status from server:", response.status);

        if (!response.ok) {
          throw new Error(`Network error: ${response.status}`);
        }

        const data = await response.json();
        console.log("Ответ от бэкенда:", data);

        if (data.message === "Successfully authenticated") {
          if (isLocalStorageAvailable()) {
            localStorage.setItem('accessToken', data.access);
            localStorage.setItem('refreshToken', data.refresh);
            window.location.href = '/home';
          } else {
            alert('localStorage недоступен. Сохранение токенов не выполнено.');
          }
        } else {
          alert(data.error || 'Ошибка при авторизации через Telegram');
        }
      } catch (error) {
        console.error('Ошибка:', error);
        alert(`Произошла ошибка: ${error.message}`);
      }
    };

    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?15';
    script.async = true;
    script.setAttribute('data-telegram-login', 'itoutsource24_bot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-userpic', 'true');
    script.setAttribute('data-request-access', 'write');
    script.setAttribute('data-onauth', 'handleTelegramAuth(user)');
    document.getElementById('telegram-login-button').appendChild(script);
  }, [login]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'linear-gradient(135deg, #101020, #1b1b3a)',
        color: '#fff',
        fontFamily: 'Arial, sans-serif',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Background code effect */}
      <pre
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          color: 'rgba(255, 255, 255, 0.05)',
          fontSize: '1.2rem',
          overflow: 'hidden',
          zIndex: '0',
          padding: '20px',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          pointerEvents: 'none',
        }}
      >
        {`// More code lines...
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}`}
      </pre>
      <Card
        css={{
          width: '420px',
          padding: '30px',
          boxShadow: '0 8px 40px rgba(0, 0, 0, 0.4)',
          backgroundColor: 'rgba(45, 45, 77, 0.95)',
          borderRadius: '12px',
          zIndex: '1',
        }}
      >
        <motion.div
          initial={{ y: -40, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
          style={{ textAlign: 'center', marginBottom: '25px' }}
        >
          <h2 style={{ fontSize: '2.2rem', fontWeight: 'bold', color: '#ffffff' }}>
            Вход в IT CRM
          </h2>
        </motion.div>

        {/* Container for Telegram Login Widget */}
        <div
          id="telegram-login-button"
          style={{
            textAlign: 'center',
            marginTop: '20px',
            marginBottom: '30px',
          }}
        ></div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
          style={{ textAlign: 'center' }}
        >
          <span style={{ fontSize: '1rem', color: '#bbb' }}>Нет аккаунта? </span>
          <a
            href="/register"
            style={{
              color: '#ff4d4d',
              textDecoration: 'none',
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            Зарегистрироваться
          </a>
        </motion.div>
      </Card>
      {/* Running marquee at the bottom */}
      <div className="marquee">
        <img
          src="https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/197.png"
          alt="Амбрион"
        />
        <span>Амбрион живет здесь в IT CRM</span>
      </div>
      <style>{`
        .marquee {
          position: fixed;
          bottom: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          animation: marquee 12s linear infinite;
          white-space: nowrap;
          font-size: 1.5rem;
          color: #ffeb3b;
          z-index: 2;
        }

        .marquee img {
          width: 40px;
          margin-right: 8px;
        }

        @keyframes marquee {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(-100%);
          }
        }
      `}</style>
    </div>
  );
}

export default Login;
