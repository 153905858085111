// src/components/FrameCompanies.jsx

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./style.css";

// Компонент FrameCompanies отвечает за отображение списка компаний и создание новых
const FrameCompanies = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true); // Состояние загрузки
  const [error, setError] = useState(null); // Состояние ошибки
  const navigate = useNavigate();
  const modalRef = useRef(null);

  // Функция для получения токена из localStorage
  const getToken = () => {
    try {
      return typeof window !== "undefined" && window.localStorage
        ? localStorage.getItem("accessToken")
        : null;
    } catch (e) {
      console.error("Доступ к localStorage не разрешен:", e);
      return null;
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const token = getToken();
      if (!token) {
        setError("Нет токена авторизации");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get("https://итоуцоурце24.рф/api/companies/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setCompanies(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Ошибка при получении компаний:", err);
        setError("Ошибка при получении компаний");
        setLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCompanyClick = (id) => navigate(`/companies/${id}`);

  const handleAddCompany = async (name, description, telegramId, customerName, customerPosition, inn, kpp) => {
    const token = getToken();
    if (!token) {
      alert("Нет токена авторизации.");
      return;
    }

    try {
      // Проверка существования компании с таким ИНН
      const checkResponse = await axios.get("https://итоуцоурце24.рф/api/companies/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      const existingCompany = checkResponse.data.find((company) => company.inn === inn);

      if (existingCompany) {
        alert("Компания с таким ИНН уже существует.");
        return;
      }

      // Создание новой компании
      const response = await axios.post(
        "https://итоуцоурце24.рф/api/companies/",
        {
          name,
          description,
          telegramId,
          customer_name: customerName,
          customer_position: customerPosition,
          inn,
          kpp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      setCompanies([...companies, response.data]);
      setShowCreateForm(false);
    } catch (error) {
      console.error("Ошибка при создании компании:", error);
      alert("Произошла ошибка при создании компании.");
    }
  };

  // Закрытие формы при клике вне области формы
  const handleOverlayClick = (e) => {
    if (e.target === modalRef.current) {
      setShowCreateForm(false);
    }
  };

  return (
    <div className="frame-companies">
      <div className="filter-wrapper">
        <input
          type="text"
          placeholder="Поиск компании"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="filter-input"
        />
        <button className="create-button" onClick={() => setShowCreateForm(true)}>
          + Добавить компанию
        </button>
      </div>

      {loading ? (
        <div className="companies-list">Загрузка компаний...</div>
      ) : error ? (
        <div className="companies-list error">Ошибка: {error}</div>
      ) : (
        <div className="companies-list">
          {filteredCompanies.length === 0 ? (
            <div>Нет компаний, соответствующих поиску.</div>
          ) : (
            filteredCompanies.map((company) => (
              <div
                key={company.id}
                onClick={() => handleCompanyClick(company.id)}
                className="company-card"
              >
                <h4>{company.name}</h4>
                <p>{company.description}</p>
                <small>Telegram ID: {company.telegramId || "Не указан"}</small>
              </div>
            ))
          )}
        </div>
      )}

      {showCreateForm && (
        <div className="modal-overlay" onClick={handleOverlayClick} ref={modalRef}>
          <div className="modal-content">
            <h4>Создать компанию</h4>
            <CreateCompanyForm onSubmit={handleAddCompany} onClose={() => setShowCreateForm(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

// Компонент формы создания компании
const CreateCompanyForm = ({ onSubmit, onClose }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [telegramId, setTelegramId] = useState("");
  const [inn, setInn] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPosition, setCustomerPosition] = useState("");
  const [kpp, setKpp] = useState("");
  const [loading, setLoading] = useState(false); // Состояние загрузки при запросе
  const [error, setError] = useState(null); // Состояние ошибки при запросе

  // Функция для получения токена из localStorage
  const getToken = () => {
    try {
      return typeof window !== "undefined" && window.localStorage
        ? localStorage.getItem("accessToken")
        : null;
    } catch (e) {
      console.error("Доступ к localStorage не разрешен:", e);
      return null;
    }
  };

  // Функция для получения данных компании по ИНН через DaData API
  const fetchCompanyData = async (inn) => {
    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
    const token = "85e3adae9474c05a80a34e91a7cce270f7ed4878"; // **Важно:** Не храните API-ключи на фронтенде

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ query: inn }),
    };

    try {
      setLoading(true);
      setError(null);
      const response = await fetch(url, options);
      if (response.ok) {
        const data = await response.json();
        if (data.suggestions && data.suggestions.length > 0) {
          const companyData = data.suggestions[0].data;
          setName(companyData.name.full_with_opf || "");
          setKpp(companyData.kpp || "");
          setCustomerName(companyData.management?.name || "");
          setCustomerPosition(companyData.management?.post || "");
        } else {
          console.error("Компания не найдена по указанному ИНН");
          setError("Компания не найдена по указанному ИНН");
        }
      } else {
        console.error("Ошибка при получении данных из DaData:", response.statusText);
        setError("Ошибка при получении данных из DaData");
      }
    } catch (error) {
      console.error("Ошибка при получении данных из DaData:", error);
      setError("Ошибка при получении данных из DaData");
    } finally {
      setLoading(false);
    }
  };

  const handleInnChange = (e) => {
    const innValue = e.target.value;
    setInn(innValue);
    if (innValue.length === 10 || innValue.length === 12) {
      fetchCompanyData(innValue);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Валидация формы
    if (!inn || !name) {
      alert("ИНН и название компании обязательны.");
      return;
    }

    onSubmit(name, description, telegramId, customerName, customerPosition, inn, kpp);
  };

  return (
    <form onSubmit={handleSubmit} className="create-company-form">
      <input
        type="text"
        placeholder="ИНН организации"
        value={inn}
        onChange={handleInnChange}
        required
        pattern="\d{10}|\d{12}"
        title="ИНН должен состоять из 10 или 12 цифр"
      />
      <input
        type="text"
        placeholder="Название компании"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Описание компании"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <input
        type="text"
        placeholder="Telegram ID"
        value={telegramId}
        onChange={(e) => setTelegramId(e.target.value)}
      />
      <input
        type="text"
        placeholder="ФИО Заказчика"
        value={customerName}
        onChange={(e) => setCustomerName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Должность заказчика"
        value={customerPosition}
        onChange={(e) => setCustomerPosition(e.target.value)}
      />
      <input
        type="text"
        placeholder="КПП"
        value={kpp}
        onChange={(e) => setKpp(e.target.value)}
      />
      {loading && <p>Загрузка данных компании...</p>}
      {error && <p className="error-message">{error}</p>}
      <button type="submit" className="submit-button" disabled={loading}>
        Создать компанию
      </button>
      <button type="button" onClick={onClose} className="cancel-button">
        Отмена
      </button>
    </form>
  );
};

export default FrameCompanies;
