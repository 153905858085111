import React from "react";
import { FrameReports } from "../../FrameReports"; // Импорт правильного компонента

import "./style.css"; // Ваши стили

const Reports = () => {
  return (
    <div className="home-page">
      <FrameReports /> {/* Меню слева */}

    </div>
  );
};

export default Reports;
