import React from "react";
import "./style.css";

export const FrameCash = () => {
  return (
    <div className="frame-cash-container">
      <div className="header">
        <div className="card">
          <h2>5000,00</h2>
          <p>Поступило на счет</p>
        </div>
        <div className="card">
          <h2>300,00</h2>
          <p>Налоги</p>
        </div>
      </div>
      <div className="table-container">
        <div className="table-header">
          <span>Имя</span>
          <span>Сумма</span>
          <span>Процент</span>
        </div>
        <div className="table-row">
          <span>Алексей</span>
          <span>1000,00</span>
          <span>25%</span>
        </div>
        <div className="table-row">
          <span>Антон</span>
          <span>1000,00</span>
          <span>25%</span>
        </div>
        <div className="table-row">
          <span>Евгений</span>
          <span>1000,00</span>
          <span>25%</span>
        </div>
        <div className="table-row">
          <span>Олег</span>
          <span>1000,00</span>
          <span>25%</span>
        </div>
      </div>
    </div>
  );
};

export default FrameCash;
